<template>
  <PageWithLayout :isBodyBg="false">
    <div class="group_box">
      <BoxView title="Modals" :grid="100">
        <TableView>
          <template v-slot:tbl_top>
            <div class="area_left">
              <strong class="tit_tbl">텍스트 모달</strong>
              <p class="desc_tbl">텍스트 컨텐츠에 적용됩니다</p>
            </div>
            <div class="area_right">
              <Button
                btnSize="small"
                btnStyle="secondary_border"
                text="미리보기"
                @onClickBtn="onClickModals()"/>
            </div>
          </template>
          <template v-slot:tbl_colgroup>
            <col style="width:20%"/>
            <col style="width:30%" />
            <col style="width:20%"/>
            <col style="width:30%" />
          </template>
          <template v-slot:tbl_body>
            <tr>
              <th>title</th>
              <td colspan="3">
                <Input
                  placeholder="title"
                  :value.sync="ModalsTitle"/>
              </td>
            </tr>
            <tr>
              <th>sub title</th>
              <td colspan="3">
                <Input
                  placeholder="sub title"
                  :value.sync="ModalsSubTitle"/>
              </td>
            </tr>
            <tr>
              <th>max width</th>
              <td colspan="3">
                <Input
                  placeholder="max width (default : 654)"
                  :value.sync="ModalMaxWidth"/>
              </td>
            </tr>
            <tr>
              <th>content</th>
              <td colspan="3">
                <Textarea
                  placeholder="content"
                  :max="5000"
                  :value.sync="ModalContent"/>
              </td>
            </tr>
            <tr>
              <th>close button text</th>
              <td>
                <Input
                  placeholder="close button text"
                  :value.sync="ModalsCloseBtnText"/>
              </td>
              <th>compelete button text</th>
              <td>
                <Input
                  placeholder="compelete button text"
                  :value.sync="ModalsCompeleteBtnText"/>
              </td>
            </tr>
          </template>
        </TableView>
      </BoxView>
      <BoxView title="Alerts" :grid="100">
        <TableView>
          <template v-slot:tbl_top>
            <div class="area_left">
              <strong class="tit_tbl">Alert</strong>
              <p class="desc_tbl">Alert에 적용됩니다</p>
            </div>
            <div class="area_right">
              <Button
                btnSize="small"
                btnStyle="secondary_border"
                text="미리보기"
                @onClickBtn="onClickAlert()"/>
            </div>
          </template>
          <template v-slot:tbl_colgroup>
            <col style="width:20%"/>
            <col style="width:30%" />
            <col style="width:20%"/>
            <col style="width:30%" />
          </template>
          <template v-slot:tbl_body>
            <tr>
              <th>message</th>
              <td colspan="3">
                <Input
                  placeholder="message"
                  :value.sync="alertMsg"/>
              </td>
            </tr>
            <tr>
              <th>close button text</th>
              <td>
                <Input
                  placeholder="close button text"
                  :value.sync="alertCloseBtnText"/>
              </td>
              <th>compelete button text</th>
              <td>
                <Input
                  placeholder="compelete button text"
                  :value.sync="alertCompeleteBtnText"/>
              </td>
            </tr>
          </template>
        </TableView>
      </BoxView>
      <BoxView title="Toast" :grid="100">
        <div class="area_float">
          <div class="area_right">
            <Button
              btnSize="small"
              btnStyle="secondary_border"
              text="Toast"
              :disabled="getIsToastPopup"
              @onClickBtn="onClickToast()"/>
          </div>
          <div class="area_overflow">
            <Input
              placeholder="Toast message"
              :value.sync="toastMsg"/>
          </div>
        </div>
      </BoxView>
    </div>
    <template v-slot:popup>
      <GuidePopup
        v-if="isGuidePopup"
        :title="ModalsTitle"
        :subTitle="ModalsSubTitle"
        :maxWidth="ModalMaxWidth"
        :ModalContent="ModalContent"
        :closeBtnText="ModalsCloseBtnText"
        :compeleteBtnText="ModalsCompeleteBtnText"
        @onClickClose="onClickCloseGuidePopup()"
        @onClickComplete="onClickCompleteGuidePopup()"/>
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import BoxView from '@lemontree-ai/lemontree-admin-common-front/components/common/boxView/BoxView';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import Textarea from '@lemontree-ai/lemontree-admin-common-front/components/common/textarea/Textarea';
import Sticky from '@lemontree-ai/lemontree-admin-common-front/components/common/sticky/Sticky';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';

import GuidePopup from '@/views/guide/layer/popup/GuidePopup';

import { mapGetters } from 'vuex';

export default {
  name:'Layer',
  components:{
    PageWithLayout,
    BoxView,
    TableView,
    IconSvg,
    Input,
    Textarea,
    Sticky,
    Button,

    GuidePopup
  },
  computed:{
    ...mapGetters('commonToast', [ 'getIsToastPopup','getIsToastMsg' ]),
  },
  data(){
    return {
      isGuidePopup:false,
      ModalsTitle:'모달제목',
      ModalsSubTitle:'',
      ModalMaxWidth:'',
      ModalContent:'일이삼사오육칠팔구십일이삼사오육칠팔구십일이삼사오육칠팔구십일이삼사오육칠팔구십일이삼사오육칠팔구십일이삼사오육칠팔구십일이삼사오육칠팔구십',
      ModalsCloseBtnText:'닫기',
      ModalsCompeleteBtnText:'확인',

      alertMsg:'알럿을 확인하시겠습니까?',
      alertCloseBtnText:'닫기',
      alertCompeleteBtnText:'확인',

      toastMsg:'토스트 등록이 완료되었습니다.'
    }
  },
  methods:{
    onClickModals(){
      this.isGuidePopup = true;
    },
    onClickCloseGuidePopup(){
      this.isGuidePopup = false;
    },
    onClickCompleteGuidePopup(){
      this.isGuidePopup = false;
      this.$store.dispatch('commonToast/fetchToastStart', '모달을 확인하였습니다');
    },
    onClickAlert(){
      const requesetAlert = () => {
        this.$store.dispatch('commonToast/fetchToastStart', '알럿을 확인하였습니다');
      };
      let alertCloseBtnText = this.alertCloseBtnText;
      let alertCompeleteBtnText = this.alertCompeleteBtnText;
      if(!alertCloseBtnText && !alertCompeleteBtnText){
        alertCloseBtnText = 'close 또는 compelete 하나는 입력필수'
      }
      this.$store.dispatch('commonAlert/fetchAlertStart', {
        alertMsg: this.alertMsg,
        closeBtnText: alertCloseBtnText,
        compeleteBtnText: alertCompeleteBtnText,
        confirmCallBack: requesetAlert,
      });
    },
    onClickToast(){
      this.$store.dispatch('commonToast/fetchToastStart', this.toastMsg);
    }
  }
}
</script>

<style scoped>
</style>