<template>
  <Popup
    :title="title"
    :subTitle="subTitle"
    :maxWidth="maxWidthNumber"
    :closeBtnText="closeBtnText"
    :compeleteBtnText="compeleteBtnText"
    @onClickClose="$emit('onClickClose')"
    @onClickComplete="$emit('onClickComplete')">
    {{ ModalContent }}
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';

export default {
  name:'GuidePopup',
  components:{
    Popup,
  },
  props:{
    title: String,
    subTitle: String,
    maxWidth: String,
    ModalContent: String,
    closeBtnText: String,
    compeleteBtnText: String,
  },
  computed:{
    maxWidthNumber(){
      if(!this.maxWidth) return null;
      return Number(this.maxWidth);
    }
  }
}
</script>